// Library imports
import { Box, Modal } from "@mui/material";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { TermsAndConditionsData } from "../../apis/constants";

export default function TermsConditions({ open, setOpen }) {
  const handleClose = () => setOpen(false);

  const costumeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "#EFEAE4",
    boxShadow: 24,
    overflow: "scroll",
    borderRadius: 2,
    "&:focus": {
      outline: "none",
    },
  };

  const CommonSummary = ({ title, description }) => {
    return (
      <div className="my-5">
        <p className="font-semibold">{title}</p>
        <p>{description}</p>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={costumeStyle}>
        <div className="bg-[#EFEAE4] fontStyle flex p-5 justify-between items-center sticky top-0">
          <p className="text-[24px]">Terms and conditions</p>

          <IoMdClose
            cursor={"pointer"}
            size={24}
            color="#6D6B69"
            onClick={handleClose}
          />
        </div>
        <div className="fontStyle px-[20px] ">
          <div className="text-[16px] p-[20px] lg:p-[20px] md:p-[18px] sm:p-[16px] phone:p-[14px]">
            <p>
              Access to and use of Opod Play store and the products and services
              available through the website (http://play.opod.in) are subject to
              the following terms, conditions, and notices (“Terms of Service”).
              By browsing through these Terms of Service and using the services
              provided by our website, you agree to all Terms of Service along
              with the Privacy Policy on our website, which may be updated by us
              from time to time. Please check this page regularly to take notice
              of any changes we may have made to the Terms of Service.
            </p>

            <CommonSummary
              title={"Introduction"}
              description={
                "The domain name (http://play.opod.in) is a site operated by HWO MEDIA PVT. LTD. is a company incorporated under the laws of India with our registered office in Mumbai, India - 400024."
              }
            />

            <CommonSummary
              title={"Services"}
              description={
                "OPOD PLAY is an online retailer of card game and board game products offered at a great value to the consumer. Upon placing an order, Opod Play store shall ship the product to you and be entitled to payment for its service."
              }
            />

            <p className="font-bold text-[18px]">
              Third-party Websites and Content
            </p>

            <p className="my-2">
              By accessing or using our website, you agree to be bound by these
              terms and conditions.
            </p>

            {TermsAndConditionsData.map((item, index) => (
              <CommonSummary
                key={index}
                title={item.title}
                description={item.description}
              />
            ))}

            <p className="font-bold text-[16px]">
              Thank you for taking the time to read through our terms and
              conditions. If you have any questions or concerns, please do not
              hesitate to contact us at hello@opod.in.
            </p>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
