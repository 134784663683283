export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";

export const increment = (gameId) => ({
  type: INCREMENT,
  payload: gameId,
});

export const decrement = (gameId) => ({
  type: DECREMENT,
  payload: gameId,
});
