// Library imports
import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import YouAreIn from "./YouAreIn";

export default function DontMissOut({ open, setOpen, setIsAlreadyOpenNotify }) {
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleClose = () => setOpen(false);

  const onClickClose = () => {
    setOpen(false);
  };

  const onClickSubmit = () => {
    setOpen(false);
    setIsAlreadyOpenNotify(true);
    setOpenConfirm(true);
  };

  const costumeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "#EFEAE4",
    boxShadow: 24,
    p: 3,
    overflow: "scroll",
    borderRadius: 2,
    "&:focus": {
      outline: "none",
    },
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={costumeStyle}>
          <div className=" h-full flex flex-col justify-between">
            <div>
              <div className="flex flex-row justify-between items-center">
                <p className="font-bold text-[20px] xl:text-[32px] lg:text-[30px] md:text-[20px] phone:text-[18px] text-center mx-auto">
                  Don't Miss Out!
                </p>
                <IoMdClose
                  cursor={"pointer"}
                  className="text-[18px] xl:text-[32px] lg:text-[28px] md:text-[24px] phone:text-[20px] text-center "
                  color="#6D6B69"
                  onClick={onClickClose}
                />
              </div>
              <p className="xl:text-[20px] lg:text-[18px] phone:text-[16px] text-[14px] my-5 fontStyle">
                Enter your email and we'll send a carrier pigeon (okay, maybe an
                email) when the game the game is ready to play! You do get bonus
                points for patience!
              </p>
              <form className="flex gap-[30px] my-[3rem] md:flex-row flex-col w-[100%] justify-between">
                <input
                  placeholder="Full Name"
                  className="w-full h-[48px] border-[1px] border-[#000] rounded-lg px-3"
                />
                <input
                  placeholder="Email *"
                  className="w-full h-[48px] border-[1px] border-[#000] rounded-lg px-3"
                />
              </form>
            </div>
            <div className="flex flex-row justify-between md:justify-center md:gap-[20px] items-center">
              <div
                onClick={onClickClose}
                className="buttonStyle w-[48%] lg:w-[20%]"
              >
                <button>CANCEL</button>
              </div>
              <div
                onClick={onClickSubmit}
                className="buttonStyle w-[48%] lg:w-[20%] bg-[#FAA51A]"
              >
                <button>SUBMIT</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <YouAreIn openConfirm={openConfirm} setOpenConfirm={setOpenConfirm} />
    </div>
  );
}
