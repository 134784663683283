// Library imports
import React, { useContext, useEffect } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { IoCartOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

// Local imports
import { decrement, increment } from "../../redux/action/gameCountAction";
import { removeFromCart } from "../../redux/action/gamesCart";
import { DropdownContext } from "../../context/dropdown";
import { useMediaQuery } from "react-responsive";
import { IoMdClose } from "react-icons/io";
import ButtonComponents from "../../components/common/ButtonComponents";

export default function Cart({ setIsHeaderShow, setIsPreOrderHeader }) {
  setIsHeaderShow(true);
  setIsPreOrderHeader(false);

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ minWidth: 576 });
  const gameData = useSelector((state) => state.cart.items);
  const navigate = useNavigate();
  const { setIsDropdownOpen } = useContext(DropdownContext);

  const { isDropdownOpen } = useContext(DropdownContext);

  useEffect(() => {
    if (!isDropdownOpen) {
      if (isMobile) {
        setIsDropdownOpen(true);
        window.location.href = "/";
      }
    }
  }, [isMobile, isDropdownOpen]);

  const onClickCheck = () => {
    navigate("/checkout");
  };

  const onExploreGames = () => {
    navigate("/#games");
  };

  const onClickRemove = (id) => {
    dispatch(removeFromCart(id));
  };

  const onPressClose = () => {
    navigate("/");
  };

  return (
    <div className="fontStyle bg-[#EFEAE5] h-[93dvh] flex flex-col justify-start">
      <div className="flex items-center flex-row justify-between px-3 py-3">
        <div className="flex flex-row items-center gap-2">
          <IoCartOutline size={28} color="black" cursor={"pointer"} />
          <p className="orderSummaryStyle">Your Cart</p>
        </div>
        <IoMdClose size={28} cursor={"pointer"} onClick={onPressClose} />
      </div>
      {gameData.length > 0 ? (
        <div className="flex justify-between flex-col px-3 h-full">
          <div>
            <div className="grid grid-flow-row-dense grid-cols-9 grid-rows-1">
              {gameData?.map((item, index) => (
                <>
                  <div key={index} className="col-span-2 w-[100%] my-4">
                    <img src={item?.image} alt={item?.title} />
                  </div>

                  <div
                    key={index}
                    className="pl-[20px] flex flex-col justify-between col-span-7 my-5"
                  >
                    <div className="flex items-center justify-between">
                      <div className="text-[24px] font-semibold font-family-Sans">
                        {item?.title}
                      </div>
                      <div
                        onClick={() => onClickRemove(item?.gameId)}
                        className="bg-[#E53B49] cursor-pointer flex justify-center items-center rounded-lg w-[30px] h-[32px]"
                      >
                        <RiDeleteBin6Line size={20} color="white" />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="flex">
                        <p className="crossed-text text-[20px] text-[#6D6B69] font-semibold font-family-Sans">
                          ₹699
                        </p>
                        <p className="text-[20px] text-[#161616] font-semibold font-family-Sans ml-[5px]">
                          Free
                        </p>
                      </div>
                      <div className="flex items-center gap-3">
                        <div
                          className="plusMinusStyle cursor-pointer"
                          onClick={() => dispatch(decrement(item.gameId))}
                        >
                          <FaMinus size={15} />
                        </div>
                        <div className="numberOfGamesStyle min-w-[30px] text-center text-[20px] border-x-[1px] border-[#7F7F7F]">
                          {item?.count}
                        </div>
                        <div
                          className="plusMinusStyle cursor-pointer"
                          onClick={() => dispatch(increment(item.gameId))}
                        >
                          <FaPlus size={15} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>

            <div className="border-[1px] border-[#000] my-5"></div>

            <div className="flex justify-between">
              <div className="text-[24px] text-[#000] font-semibold font-family-Sans">
                Total
              </div>
              <div className="text-[24px] text-[#000] font-semibold font-family-Sans">
                ₹ 0
              </div>
            </div>
          </div>

          <ButtonComponents
            className={"bg-[#FAA51A] mx-5 my-10"}
            onClick={onClickCheck}
          >
            CHECK OUT
          </ButtonComponents>
        </div>
      ) : (
        <div className="flex flex-col justify-between h-full">
          <p className="text-[#555555] m-[30px] text-[20px] text-center font-family-Sans">
            There aren't any games in your cart yet, but that can be easily
            fixed!
          </p>

          <ButtonComponents
            className={"bg-[#FAA51A] mx-5 my-10"}
            onClick={onExploreGames}
          >
            EXPLORE GAMES
          </ButtonComponents>
        </div>
      )}
    </div>
  );
}
