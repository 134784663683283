// Library imports
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { IoChevronBackOutline } from "react-icons/io5";
import { useEffect } from "react";
import hamburgerBars from "../../assets/pngs/hamburger-bars.png";

// Local imports
import { NavItems } from "../../data/header/NavItems";

export const NavButton = () => {
  const [open, setOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("home");

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        { id: "home", element: document.getElementById("home") },
        { id: "games", element: document.getElementById("games") },
        { id: "about", element: document.getElementById("about") },
        { id: "contact", element: document.getElementById("contact") },
      ];
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const scrollBottom = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      let active = "home";

      if (scrollBottom >= documentHeight - 1) {
        active = "contact";
      } else {
        for (const section of sections) {
          if (section.element) {
            const sectionTop = section.element.offsetTop;
            const sectionBottom = sectionTop + section.element.offsetHeight;
            if (
              scrollPosition >= sectionTop &&
              scrollPosition <= sectionBottom
            ) {
              active = section.id;
              break;
            }
          }
        }
      }

      setActiveSection(active);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="sm:hidden">
      <div className="hover:cursor-pointer">
        <img
          className="w-[22px] h-[16px]"
          src={hamburgerBars}
          alt=""
          onClick={toggleDrawer(!open)}
          v
        />
      </div>

      <div>
        <Drawer open={open} anchor="left" onClose={toggleDrawer(false)}>
          <Box className="w-[5rem] fontStyle lg:w-[13rem] md:w-[10rem] flex items-start mx-[2rem] my-[3rem] gap-[35px] flex-col">
            <IoChevronBackOutline
              size={30}
              color="black"
              className="mb-10"
              onClick={toggleDrawer(false)}
            />
            {!!open &&
              NavItems.map((button, index) => (
                <div
                  key={index}
                  className={`flex flex-col items-center text-[24px] ${
                    activeSection === button.text.toLocaleLowerCase()
                      ? "font-bold"
                      : "font-regular"
                  }`}
                  onClick={toggleDrawer(false)}
                >
                  <NavLink to={button.path}>{button.text}</NavLink>
                  <div
                    className={`hover:cursor-pointer rounded-full border-b-4 w-[75%] ${
                      activeSection === button.text.toLocaleLowerCase()
                        ? "border-[#FAA51A]"
                        : " border-[#00000000]"
                    }`}
                  />
                </div>
              ))}
          </Box>
        </Drawer>
      </div>
    </div>
  );
};
