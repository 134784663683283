// Library imports
import React, { useEffect, useState } from "react";
import "./Preorder.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

// Local imports
import { decrement, increment } from "../../redux/action/gameCountAction";
import { CheckOutModal } from "../../components/Modals/CheckOutModal";
import { removeFromCart } from "../../redux/action/gamesCart";
import FooterModal from "../../components/Modals/FooterModal";
import errorImage from "../../assets/pngs/error_round.png";
import successImage from "../../assets/pngs/success_round.png";
import { useNavigate } from "react-router-dom";
import backButton from "../../assets/pngs/backButton.png";

export default function Preorder({ setIsHeaderShow, setIsPreOrderHeader }) {
  setIsHeaderShow(false);
  setIsPreOrderHeader(true);

  const isMobile = useMediaQuery({ minWidth: 576 });
  const gameData = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [categoryFlag, setCategoryFlag] = useState(null);
  const navigate = useNavigate();

  const AccSchema = Yup.object().shape({
    fullName: Yup.string().required("Please Enter Your Full Name"),
    phone: Yup.string().required("Please Enter Phone Number"),
    email: Yup.string().email().required("Please Enter Email Address"),
    addressLine1: Yup.string().required("Please Enter Your Address Name"),
    addressLine2: Yup.string().required("Please Enter Your Address Name"),
    city: Yup.string().required("Please Enter Your City"),
    state: Yup.string().required("Please Enter Your State"),
    country: Yup.string().required("Please Enter your Country"),
    pincode: Yup.string().required("Please Enter your pincode"),
    couponCode: Yup.string("Please Enter Coupon Code"),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      window.location.href = "#/checkout";
    }
  }, [isMobile]);

  useEffect(() => {
    if (gameData.length === 0) {
      window.location.href = "/#games";
    }
  }, [gameData]);

  const handleClose = () => {
    setOpen(false);
  };

  const onClickExploreGames = () => {
    window.location.href = "/#games";
  };

  const handleTerms = () => {
    setOpen(true);
    setCategoryFlag("TERMS_CONDITIONS");
  };

  const handleShipping = () => {
    setOpen(true);
    setCategoryFlag("SHIPPING_POLICY");
  };

  const handlePrivacy = () => {
    setOpen(true);
    setCategoryFlag("PRIVACY_POLICY");
  };

  const handleReturn = () => {
    setOpen(true);
    setCategoryFlag("RETURN_POLICY");
  };

  const onclickExplore = () => {
    navigate("/#games");
    const gameSection = document.getElementById("games");
    if (gameSection) {
      gameSection.scrollIntoView({
        top: "40%",
        behavior: "smooth",
      });
    }
  };

  const onClickPayNow = () => {};

  const onClickCancel = () => {
    window.location.href = "/#home";
  };

  const onClickRemove = (id) => {
    dispatch(removeFromCart(id));
  };

  const CommonField = ({
    label,
    value,
    name,
    placeholder,
    onChange,
    style,
    numberOfField,
  }) => {
    return (
      <div className="flex flex-col w-full gap-3" style={style}>
        {!!label && (
          <div className="flex">
            <div>
              <p className="text-[24px] border-[1px] border-[#fff] w-[40px]  h-[40px] flex items-center justify-center rounded-full text-[#fff] font-semibold font-family-Sans">
                {numberOfField}
              </p>
            </div>

            <div className="text-[24px] ml-[20px] text-[#fff] font-family-Sans">
              {label}
            </div>
          </div>
        )}

        <input
          value={value}
          name={name}
          placeholder={placeholder}
          className="h-[48px] flex w-full p-3 sm:ml-[55px] ml-0 rounded-lg border-[1.5px] border-[#000]"
          onChange={onChange}
        />
      </div>
    );
  };

  const CommonInputContainer = ({
    label,
    value,
    value2,
    name,
    name2,
    onChange,
    numberOfField,
    error,
    error2,
    touched,
    touched2,
    placeholder,
    placeholder2,
  }) => {
    return (
      <div className="flex flex-col miniTab:flex-row w-full gap-5 justify-between items-end">
        <div className="flex flex-col w-full items-end ">
          <CommonField
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            label={label}
            numberOfField={numberOfField}
          />

          <div className="flex flex-col self-start h-[24px] mt-[6px]">
            {error && touched && (
              <div className="text-white px-[10px] font-[14px] self-start ml-[3.125rem]">
                {error}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col w-full items-end ">
          <CommonField
            value={value2}
            name={name2}
            placeholder={placeholder2}
            onChange={onChange}
          />

          <div className="flex flex-col self-start h-[24px] mt-[6px]">
            {error2 && touched2 && (
              <div className="text-white px-[10px] font-[14px] self-start ml-[3.125rem]">
                {error2}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const PaymentForm = () => {
    return (
      <div className="my-[3rem] flex justify-center flex-col overflow-y-scroll">
        <Formik
          initialValues={{
            fullName: "",
            email: "",
            firstname: "",
            lastname: "",
            addressLine1: "",
            addressLine2: "",
            country: "",
            city: "",
            state: "",
            pincode: "",
            phone: "",
          }}
          validationSchema={AccSchema}
          onSubmit={({ resetForm }) => resetForm()}
        >
          {({ errors, touched, values, handleSubmit, handleChange }) => (
            <form onSubmit={handleSubmit} className="formSubmissionStyle">
              <CommonField
                value={values.fullName}
                name={"fullName"}
                label={"Contact"}
                numberOfField={1}
                style={{ margin: "20px 0 0 0" }}
                placeholder={"Full Name*"}
                className="w-auto h-[48px] rounded-lg border-[1.5px] border-[#000] ml-[60px]"
                onChange={(e) => handleChange(e)}
              />

              <div className="flex flex-col self-start h-[24px] mt-[6px] ">
                {errors.fullName && touched.fullName && (
                  <div className="text-white pb-[20px] px-2 font-[14px] self-start ml-[3.125rem]">
                    {errors.fullName}
                  </div>
                )}
              </div>

              <CommonInputContainer
                value={values.phone}
                value2={values.email}
                name={"phone"}
                name2={"email"}
                placeholder={"Phone Number"}
                placeholder2={"Enter your Email *"}
                onChange={(e) => handleChange(e)}
                error={errors.phone}
                error2={errors.email}
                touched={touched.phone}
                touched2={touched.email}
              />

              <CommonField
                value={values.addressLine1}
                name={"addressLine1"}
                label={"Delivery details"}
                numberOfField={1}
                style={{ margin: "20px 0 0 0" }}
                placeholder={"Address Line 1*"}
                className="w-auto h-[48px] rounded-lg border-[1.5px] border-[#000] ml-[60px]"
                onChange={(e) => handleChange(e)}
              />

              <div className="flex flex-col self-start h-[10px] ">
                {errors.addressLine1 && touched.addressLine1 && (
                  <div className="text-white pb-[20px] px-2 font-[14px] self-start ml-[3.125rem]">
                    {errors.addressLine1}
                  </div>
                )}
              </div>

              <CommonField
                value={values.addressLine2}
                name={"addressLine2"}
                style={{ margin: "20px 0 0 0" }}
                placeholder={"Address Line 2*"}
                className="w-auto h-[48px] rounded-lg border-[1.5px] border-[#000] ml-[60px]"
                onChange={(e) => handleChange(e)}
              />

              <div className="flex flex-col self-start h-[24px] mt-[6px]">
                {errors.addressLine2 && touched.addressLine2 && (
                  <div className="text-white pb-[20px] px-2 font-[14px] self-start ml-[3.125rem]">
                    {errors.addressLine2}
                  </div>
                )}
              </div>

              <CommonInputContainer
                value={values.city}
                value2={values.state}
                name={"city"}
                name2={"state"}
                placeholder={"City"}
                placeholder2={"State"}
                onChange={(e) => handleChange(e)}
                error={errors.city}
                error2={errors.state}
                touched={touched.city}
                touched2={touched.state}
              />

              <CommonInputContainer
                value={values.country}
                value2={values.pincode}
                name={"country"}
                name2={"pincode"}
                placeholder={"Country*"}
                placeholder2={"Pin code*"}
                onChange={(e) => handleChange(e)}
                error={errors.country}
                error2={errors.pincode}
                touched={touched.country}
                touched2={touched.pincode}
              />

              <div className="flex mt-[3rem]">
                <div>
                  <p className="text-[24px] border-[1px] border-[#fff] w-[40px]  h-[40px] flex items-center justify-center rounded-full text-[#fff] font-semibold font-family-Sans">
                    3
                  </p>
                </div>

                <div className="text-[24px] ml-[20px] text-[#fff] font-family-Sans">
                  Payment
                </div>
              </div>

              <div
                onClick={onClickPayNow}
                className="flex justify-center items-center"
              >
                <button type="submit" className="payNowButtonStyle">
                  PAY NOW
                </button>
              </div>
            </form>
          )}
        </Formik>

        <p className="text-center lg:px-[100px] lg:pl-[150px] px-0 pl-0 text-[16px] py-[40px] text-white mr-[2rem]">
          After clicking “Pay now”, you will be redirected to PhonePe Payment
          gateway (UPI, Cards, Wallets, NetBanking) to complete your purchase
          securely.
        </p>

        <div className="underline underline-offset-2 grid grid-cols-4 lg:grid-cols-4 md:grid-cols-2 phone:grid-cols-1 gap-5 justify-center text-white font-semibold text-[20px] mr-[3rem] md:text-[16px] sm:text-[14px] phone:text-[12px] mt-[5rem]">
          <p onClick={handleTerms} className="cursor-pointer">
            Terms and conditions
          </p>
          <p onClick={handleShipping} className="cursor-pointer">
            Shipping policy
          </p>
          <p onClick={handlePrivacy} className="cursor-pointer">
            Privacy policy
          </p>
          <p onClick={handleReturn} className="cursor-pointer">
            Return policy
          </p>
        </div>
      </div>
    );
  };

  const OrderSummaryCard = () => {
    return (
      <div className="fontStyle pb-[10px] border-b-[1px] border-[#000]">
        <div className="grid grid-flow-row-dense gap-5 grid-cols-1 lg:grid-cols-7 max-splitMobile:grid-cols-7 grid-rows-1">
          {gameData?.map((item, index) => (
            <>
              <div className="max-splitMobile:col-span-2 col-span-7 lg:col-span-2 flex items-center">
                <img src={item?.image} className="" />
              </div>

              <div className="pl-0 max-splitMobile:pl-[20px] lg:pl-[20px] mt-[20px] phone:gap-0 sm:gap-[15px] gap-[15px] sm:mx-0 max-splitMobile:text-[20px] lg:mt-0 flex flex-col justify-between col-span-7 phone:col-span-5">
                <div className="flex flex-col phone:gap-0 md:gap-[15px] gap-[15px] sm:mx-0 mx-auto sm:flex-row phone:items-start items-center sm:items-start justify-between">
                  <div className="text-[14px] xl:text-[24px] max-splitMobile:text-[20px] lg:text-[20px] md:text-[16px] sm:text-[14px] pb-[7px] sm:pb-0  font-semibold">
                    {item?.title}
                  </div>

                  <p
                    onClick={() => onClickRemove(item?.gameId)}
                    className="removeTextStyle sm:mx-0 mx-auto text-[14px] pb-[7px] sm:pb-0 font-semibold text-[#E53B49] max-splitMobile:text-[18px] lg:text-[18px] md:text-[14px] sm:text-[12px] phone:text-[10px]"
                  >
                    REMOVE
                  </p>
                </div>

                <div className="flex justify-between flex-col phone:gap-0 sm:gap-[15px] gap-[15px] sm:mx-0 mx-auto sm:flex-row phone:items-start items-center md:items-start">
                  <div className="flex sm:mx-0 mx-auto pb-[7px] sm:pb-0">
                    <p className="fontStyle crossed-text text-[#6D6B69] text-[14px] font-semibold max-splitMobile:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[16px] phone:text-[14px]">
                      ₹699
                    </p>
                    <p className="text-[#161616]fontStyle text-[14px] font-semibold max-splitMobile:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[16px] phone:text-[14px] ml-[5px]">
                      Free
                    </p>
                  </div>
                  <div className="flex items-center sm:mx-0 mx-auto gap-3 pb-[7px] sm:pb-0">
                    <div
                      className="plusMinusStyle cursor-pointer"
                      onClick={() => dispatch(decrement(item.gameId))}
                    >
                      <FaMinus size={10} />
                    </div>
                    <div className="numberOfGamesStyle fontStyle text-[14px] font-semibold max-splitMobile:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[16px] phone:text-[14px]">
                      {item?.count}
                    </div>
                    <div
                      className="plusMinusStyle cursor-pointer"
                      onClick={() => dispatch(increment(item.gameId))}
                    >
                      <FaPlus size={10} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>

        <Formik
          initialValues={{ couponCode: "" }}
          validationSchema={AccSchema}
          onSubmit={({ resetForm }) => {
            resetForm();
          }}
          validate={(values) => {
            const errors = {};
            if (!/^[A-Z0-9]{6,}$/.test(values.couponCode)) {
              errors.couponCode = <ErrorComponent />;
            }
            return errors;
          }}
        >
          {({ errors, touched, values, handleSubmit, handleChange }) => (
            <form
              onSubmit={handleSubmit}
              className="flex md:flex-row flex-col justify-between mt-[26px] mb-[10px]"
            >
              <div className="flex w-full flex-col">
                <input
                  placeholder="Enter coupon code"
                  value={values.couponCode}
                  name="couponCode"
                  onChange={(e) => handleChange(e)}
                  className="md:w-[90%] lg:w-[70%] w-full h-[40px] border-[1px] border-[#000] rounded-lg px-3 bg-[#EFEAE4]"
                />
                {values.couponCode &&
                errors.couponCode &&
                touched.couponCode ? (
                  <div className="text-[#E53B49] mt-2 font-[14px]">
                    {errors.couponCode}
                  </div>
                ) : (
                  values.couponCode &&
                  touched.couponCode && (
                    <div className="flex flex-row items-center justify-between mt-2">
                      <div className="flex flex-row items-center gap-2">
                        <img src={successImage} className="w-[24px] h-[24px]" />
                        <p className="text-[#13B57B] font-[14px] self-start">
                          60% off applied on you order
                        </p>
                      </div>
                      <p className="font-semibold text-[#13B57B] text-[20px] flex absolute right-[35px]">
                        - ₹ 798
                      </p>
                    </div>
                  )
                )}
              </div>

              <button
                type="submit"
                className={`underline ${
                  values.couponCode || touched.couponCode
                    ? "text-black"
                    : "text-[#808080]"
                } underline-offset-2 lg:text-[20px] md:text-[18px] sm:text-[16px] font-semibold self-start pt-1`}
              >
                APPLY
              </button>
            </form>
          )}
        </Formik>
      </div>
    );
  };

  const ErrorComponent = () => {
    return (
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <img src={errorImage} className="w-[24px] h-[24px]" />
          <p className="text-[#E53B49] font-[14px] self-start">
            Invalid Coupon Code
          </p>
        </div>
        <p className="font-semibold text-[20px] flex absolute right-[20px]">
          INVALID
        </p>
      </div>
    );
  };

  const OrderSummary = () => {
    return (
      <div className="fontStyle flex h-full flex-col justify-between">
        {!!gameData?.length > 0 && (
          <div className="p-[30px] select-none">
            <div className="flex items-center justify-between">
              <p className="text-[24px] orderSummaryStyle">Order Summary</p>
            </div>
            <div className="mt-[20px]">
              <OrderSummaryCard />
            </div>

            <div className="mt-[20px]">
              <div className="flex justify-between">
                <div className="text-[#83817e] text-[14px] max-splitMobile:text-[20px] lg:text-[20px] md:text-[16px] sm:text-[14px] pb-[2px] sm:pb-0">
                  Subtotal
                </div>
                <div className="text-[#000] text-[14px] max-splitMobile:text-[20px] lg:text-[20px] md:text-[16px] sm:text-[14px] pb-[7px] sm:pb-0">
                  ₹ 0
                </div>
              </div>
              <div className="flex flex-col md:items-center sm:flex-row justify-between mt-[10px]">
                <div className="text-[#83817e] items-center gap-2 text-[14px] xl:text-[20px] max-splitMobile:text-[16px] lg:text-[16px] md:text-[14px] sm:text-[12px]">
                  Shipping & taxes
                </div>
                <div className="text-[#000] text-[14px] max-splitMobile:text-[20px] lg:text-[20px] md:text-[16px] sm:text-[14px] ">
                  ₹ 99
                </div>
              </div>

              <div className="flex justify-between mt-[10px]">
                <div className="text-[#000] font-normal text-[14px] max-splitMobile:text-[24px] lg:text-[24px] md:text-[18px] sm:text-[16px]">
                  Total
                </div>
                <div className="text-[#000] font-semibold text-[14px] max-splitMobile:text-[24px] lg:text-[24px] md:text-[18px] sm:text-[16px]">
                  ₹ 99
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  // const PaymentModal = () => {
  //   const [isComplete, setIsComplete] = useState(false);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       setIsComplete(true);
  //     }, 3000);
  //   }, [isComplete]);

  //   return (
  //     <div>
  //       <Modal
  //         open={open}
  //         onClose={handleClose}
  //         aria-labelledby="modal-modal-title"
  //         aria-describedby="modal-modal-description"
  //       >
  //         <Box sx={costumeStyle}>
  //           <div className="flex flex-col justify-between h-full">
  //             <div>
  //               <div className="flex items-center justify-between">
  //                 {!!isComplete ? (
  //                   <p className="text-[32px] xl:text-[32px] lg:text-[30px] md:text-[28px] sm:text-[24px] phone:text-[20px] font-bold">
  //                     Thank you for your order!
  //                   </p>
  //                 ) : (
  //                   <p className="text-[32px] xl:text-[32px] lg:text-[30px] md:text-[28px] sm:text-[24px] phone:text-[20px] font-bold">
  //                     Uh oh! Payment glitch detected
  //                   </p>
  //                 )}
  //                 <IoMdClose
  //                   cursor={"pointer"}
  //                   size={32}
  //                   color="#6D6B69"
  //                   onClick={handleClose}
  //                 />
  //               </div>

  //               <div className="sm:flex justify-center items-center hidden">
  //                 {!!isComplete ? (
  //                   <img
  //                     src={dileveryBoy}
  //                     className="w-auto h-[294px] lg:h-[300px] md:h-[250px] phone:h-[200px]"
  //                   />
  //                 ) : (
  //                   <img
  //                     src={bugFix}
  //                     className="w-auto h-[294px] lg:h-[300px] md:h-[250px] phone:h-[200px]"
  //                   />
  //                 )}
  //               </div>

  //               <div className="fontStyle mt-3 text-[20px] xl:text-[20px] sm:text-[18px] phone:text-[18px]">
  //                 {!!isComplete ? (
  //                   <div>
  //                     <p>
  //                       Your order for S for Startup card game with order number #XXXX has
  //                       been confirmed and will be reaching you soon. Get ready
  //                       for epic ventures at your next game night. We're
  //                       processing your order already and you'll receive a
  //                       shipping confirmation message to [email address/
  //                       Whatsapp number]. If you have any questions, don't
  //                       hesitate to contact us at [email].
  //                     </p>

  //                     <p className="mt-3">
  //                       In the meantime, why not brush up on the game rules or
  //                       explore our game library! Happy Gaming!
  //                     </p>
  //                   </div>
  //                 ) : (
  //                   <div>
  //                     <p>
  //                       Worry not! our tech wizards are on it! Hold off on
  //                       re-paying for now. We'll confirm your order as soon as
  //                       the payment goes through. If the error persists, we'll
  //                       send a fresh payment link to your rescue via [email
  //                       address/WhatsApp number]. If you have any questions,
  //                       don't hesitate to contact us at [email].
  //                     </p>

  //                     <p className="mt-3">
  //                       In the meantime, relax and explore more games while we
  //                       work on it.
  //                     </p>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>

  //             <div className="flex md:flex-row flex-col justify-center md:gap-[20px] items-center">
  //               <div className="buttonStyle w-[80%] lg:w-[20%]">
  //                 <button>HOW TO PLAY</button>
  //                 <FaPlay />
  //               </div>

  //               <div className="buttonStyle w-[80%] lg:w-[20%] bg-[#FAA51A]">
  //                 <button>Explore games</button>
  //               </div>
  //             </div>
  //           </div>
  //         </Box>
  //       </Modal>
  //     </div>
  //   );
  // };

  return (
    <div class="pl-[2rem] bg-[#e53b49] h-[90.3svh] fontStyle">
      <div class="grid grid-flow-row-dense grid-cols-6 relative grid-rows-1 h-[100%]">
        <div class="col-span-6 splitMobile:col-span-4 overflow-y-scroll">
          <div
            onClick={onClickCancel}
            className="flex flex-row items-center mx-5 cursor-pointer gap-2 mt-3 "
          >
            <img src={backButton} className="w-auto h-[42px]" />
            <p className="font-semibold text-[24px] text-white underline underline-offset-2">
              Cancel order
            </p>
          </div>
          <div className="splitMobile:hidden bg-[#efeae5] mr-8">
            <OrderSummary />
          </div>
          <PaymentForm />
        </div>
        <div class="max-splitMobile:hidden col-span-2 z-10 right-0 bg-[#efeae5] h-[100%] overflow-y-scroll">
          <OrderSummary />
        </div>
        <div>
          {/* <PaymentModal /> */}
          <CheckOutModal openModal={openModal} setOpenModal={setOpenModal} />
          <FooterModal
            open={open}
            categoryFlag={categoryFlag}
            setOpen={setOpen}
          />
        </div>
      </div>
    </div>
  );
}
