// Library imports
import React from "react";

// Local imports
import TermsConditions from "./TermsConditions";
import Privacy from "./Privacy";
import Return from "./Return";
import Shipping from "./Shipping";

export default function FooterModal({ open, setOpen, categoryFlag }) {
  const handleTerms = () => {
    switch (categoryFlag) {
      case "TERMS_CONDITIONS":
        return <TermsConditions open={open} setOpen={setOpen} />;

      case "SHIPPING_POLICY":
        return <Shipping open={open} setOpen={setOpen} />;

      case "PRIVACY_POLICY":
        return <Privacy open={open} setOpen={setOpen} />;

      case "RETURN_POLICY":
        return <Return open={open} setOpen={setOpen} />;

      default:
        return <TermsConditions open={open} setOpen={setOpen} />;
    }
  };

  return handleTerms();
}
