// Library imports
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import { FaPlay } from "react-icons/fa";

// Local imports
import dileveryBoy from "../../assets/pngs/dileveryBoy.png";
import bugFix from "../../assets/pngs/bugFix.png";

export const CheckOutModal = ({ openModal, setOpenModal }) => {
  const [isComplete, setIsComplete] = useState(false);
  const handleClose = () => setOpenModal(false);

  const costumeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    height: "85%",
    bgcolor: "#EFEAE4",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    "&:focus": {
      outline: "none",
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setIsComplete(true);
    }, 3000);
  }, [isComplete]);

  const onClickExplore = () => {
    window.location.href = "/#games";
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={costumeStyle}>
          <div className="flex flex-col justify-between h-full">
            <div>
              <div className="flex items-center justify-between">
                {!!isComplete ? (
                  <p className="text-[32px] xl:text-[32px] lg:text-[30px] md:text-[28px] sm:text-[24px] phone:text-[20px] font-bold">
                    Thank you for your order!
                  </p>
                ) : (
                  <p className="text-[32px] xl:text-[32px] lg:text-[30px] md:text-[28px] sm:text-[24px] phone:text-[20px] font-bold">
                    Uh oh! Payment glitch detected
                  </p>
                )}
                <IoMdClose
                  cursor={"pointer"}
                  size={32}
                  color="#6D6B69"
                  onClick={handleClose}
                />
              </div>

              <div className="sm:flex justify-center items-center hidden">
                {!!isComplete ? (
                  <img
                    src={dileveryBoy}
                    className="w-auto h-[294px] lg:h-[300px] md:h-[250px] phone:h-[200px]"
                  />
                ) : (
                  <img
                    src={bugFix}
                    className="w-auto h-[294px] lg:h-[300px] md:h-[250px] phone:h-[200px]"
                  />
                )}
              </div>

              <div className="fontStyle mt-3 text-[20px] xl:text-[20px] sm:text-[18px] phone:text-[20px]">
                {!!isComplete ? (
                  <div>
                    <p>
                      Your order for S for Startup card game with order number
                      #XXXX has been confirmed and will be reaching you soon.
                      Get ready for epic ventures at your next game night. We're
                      processing your order already and you'll receive a
                      shipping confirmation message to [email address/ Whatsapp
                      number]. If you have any questions, don't hesitate to
                      contact us at [email].
                    </p>

                    <p className="mt-3">
                      In the meantime, why not brush up on the game rules or
                      explore our game library! Happy Gaming!
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      Worry not! our tech wizards are on it! Hold off on
                      re-paying for now. We'll confirm your order as soon as the
                      payment goes through. If the error persists, we'll send a
                      fresh payment link to your rescue via [email
                      address/WhatsApp number]. If you have any questions, don't
                      hesitate to contact us at [email].
                    </p>

                    <p className="mt-3">
                      In the meantime, relax and explore more games while we
                      work on it.
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-row justify-center gap-[20px]  items-center">
              <div className="buttonStyle">
                <button>HOW TO PLAY</button>
                <FaPlay />
              </div>

              <div
                onClick={onClickExplore}
                className="buttonStyle bg-[#FAA51A]"
              >
                <button>Explore games</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
