// Library imports
import { useContext } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

// Local imports
import nav_icon from "../../assets/pngs/nav_icon.png";
import cart from "../../assets/pngs/cart_white.png";
import { DropdownContext } from "../../context/dropdown";
import { useNavigate } from "react-router-dom";
import CommonCartComponent from "../common/CommonCartComponent";

export default function ProrderHeader() {
  const navigate = useNavigate();
  const { setIsDropdownOpen } = useContext(DropdownContext);
  const data = useSelector((state) => state.cart.items);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const totalCount = data.reduce((total, item) => total + item.count, 0);

  const onClickLogo = () => {
    navigate("/#home");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleDrawer = (newOpen) => () => {
    if (isMobile) {
      navigate("/cart");
    } else {
      setIsDropdownOpen(newOpen);
    }
  };

  return (
    <div className="bg-[#E53B49] cursor-pointer mx-auto flex flex-row max-sm:px-5 sm:px-[5vw] lg:px-[80px] h-16 justify-between items-center sticky top-0 z-[1001] drop-shadow-md">
      <div onClick={onClickLogo} className="w-[53px] h-auto ml-5">
        <img
          src={nav_icon}
          className="w-[48px] h-[57px] max-sm:mx-auto cursor-pointer"
        />
      </div>
      <div className="flex flex-col items-center">
        <p className="flex flex-col items-center text-[24px] text-white font-bold">
          Check Out
        </p>
        <div className="hover:cursor-pointer rounded-full border-b-4 w-[75%] border-[#FAA51A]" />
      </div>

      <div onClick={toggleDrawer(true)} className="relative cursor-pointer">
        <img src={cart} className="w-auto h-[35px]" />
        {totalCount > 0 && (
          <p className="bg-[#000] flex justify-center items-center pb-[2px] text-center text-[14px] rounded-full top-0 right-0 text-white w-[20px] h-[20px] absolute">
            {totalCount}
          </p>
        )}
      </div>

      <CommonCartComponent />
    </div>
  );
}
