// Library imports
import React from "react";
import { useContext } from "react";
import "../header/index.css";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { IoMdClose } from "react-icons/io";
import { useMediaQuery } from "react-responsive";

// Local imports
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import cartBlack from "../../assets/pngs/cart_black.png";
import { increment, decrement } from "../../redux/action/gameCountAction";
import { DropdownContext } from "../../context/dropdown";
import { removeFromCart } from "../../redux/action/gamesCart";
import ButtonComponents from "../common/ButtonComponents";
import { useNavigate } from "react-router-dom";

export default function CommonCartComponent() {
  const { isDropdownOpen, setIsDropdownOpen } = useContext(DropdownContext);
  const data = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    if (isMobile) {
      navigate("/cart");
    } else {
      setIsDropdownOpen(newOpen);
    }
  };

  const onClickExploreGames = () => {
    setIsDropdownOpen(false);
    window.location.href = "/#games";
  };

  const onClickRemove = (id) => {
    dispatch(removeFromCart(id));
  };

  const onClickCheckOut = () => {
    setIsDropdownOpen(false);
    navigate("/preorder");
  };

  return (
    <>
      {!!isDropdownOpen && (
        <Drawer
          open={isDropdownOpen}
          anchor="right"
          onClose={toggleDrawer(false)}
        >
          <Box
            className="w-[20rem] flex h-full lg:w-[27rem] md:w-[23rem]"
            role="presentation"
          >
            {!!data?.length > 0 ? (
              <div className="p-[30px] select-none flex justify-between flex-col">
                <div>
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center gap-4 justify-between">
                      <img src={cartBlack} className="w-auto h-[35px]" />
                      <p className="text-[24px] orderSummaryStyle">Your Cart</p>
                    </div>
                    <IoMdClose
                      size={25}
                      cursor={"pointer"}
                      onClick={toggleDrawer(false)}
                    />
                  </div>
                  <div className="mt-[20px]">
                    {data?.map((item, index) => (
                      <div
                        key={index}
                        className="grid my-[30px] grid-flow-row-dense grid-cols-9 grid-rows-1 pb-[10px] border-b-[1px] border-[#000]"
                      >
                        <div className="col-span-2 w-[100%]">
                          <img src={item?.image} alt={item?.title} />
                        </div>
                        <div className="pl-[20px] flex flex-col justify-between col-span-7">
                          <div className="flex flex-col lg:items-center justify-between lg:flex-row">
                            <p className="text-[24px] font-semibold font-family-Sans">
                              {item?.title}
                            </p>

                            <p
                              onClick={() => onClickRemove(item?.gameId)}
                              className="removeTextStyle phone:mx-0 mx-auto text-[14px] pb-[7px] sm:pb-0 font-semibold text-[#E53B49] lg:text-[18px] md:text-[16px] sm:text-[14px] phone:text-[12px]"
                            >
                              REMOVE
                            </p>
                          </div>

                          <div className="flex gap-2 lg:gap-0 flex-col lg:flex-row justify-between">
                            <div className="flex">
                              <p className="crossed-text text-[20px] text-[#6D6B69] font-semibold font-family-Sans">
                                ₹ 699
                              </p>
                              <p className="text-[20px] text-[#161616] font-semibold font-family-Sans ml-[5px]">
                                Free
                              </p>
                            </div>
                            <div className="flex items-center gap-3">
                              <div
                                className="plusMinusStyle cursor-pointer"
                                onClick={() => dispatch(decrement(item.gameId))}
                              >
                                <FaMinus size={15} />
                              </div>
                              <div className="numberOfGamesStyle min-w-[30px] text-center text-[20px] border-x-[1px] border-[#7F7F7F]">
                                {item?.count}
                              </div>
                              <div
                                className="plusMinusStyle cursor-pointer"
                                onClick={() => dispatch(increment(item.gameId))}
                              >
                                <FaPlus size={15} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-[20px]">
                    <div className="flex justify-between">
                      <div className="text-[20px] text-[#83817e] font-family-Sans">
                        Subtotal
                      </div>
                      <div className="text-[20px] text-[#000] font-semibold font-family-Sans">
                        ₹ 0
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between mt-[16px]">
                      <div className="text-[20px] text-[#83817e] font-family-Sans">
                        Shipping & taxes
                      </div>
                      <div className="text-[20px] font-semibold text-[#000] font-family-Sans">
                        ₹ 99
                      </div>
                    </div>
                    <div className="flex justify-between mt-[16px]">
                      <div className="text-[24px] text-[#000] font-semibold font-family-Sans">
                        Total
                      </div>
                      <div className="text-[24px] text-[#000] font-semibold font-family-Sans">
                        ₹ 99
                      </div>
                    </div>
                  </div>
                </div>

                <ButtonComponents
                  className={"bg-[#FAA51A] mx-5 my-10 text-[20px]"}
                  onClick={onClickCheckOut}
                  style={{ fontSize: "20px" }}
                >
                  CHECK OUT
                </ButtonComponents>
              </div>
            ) : (
              <div className="flex px-[10px] flex-col justify-between w-[100%] bg-[#EFEAE4]">
                <div>
                  <div className="flex w-full flex-row justify-between p-5">
                    <div className="flex flex-row gap-3">
                      <img src={cartBlack} className="w-auto h-[35px]" />
                      <p className="text-[24px] orderSummaryStyle">Your Cart</p>
                    </div>
                    <IoMdClose
                      size={25}
                      onClick={toggleDrawer(false)}
                      cursor={"pointer"}
                    />
                  </div>

                  <p className="text-[#555555] my-[30px] text-[20px] text-center font-family-Sans">
                    There aren't any games in your cart yet, but that can be
                    easily fixed!
                  </p>
                </div>

                <ButtonComponents
                  className={"bg-[#FAA51A] mx-5 my-10 text-[20px]"}
                  onClick={onClickExploreGames}
                  style={{ fontSize: "20px" }}
                >
                  EXPLORE GAMES
                </ButtonComponents>
              </div>
            )}
          </Box>
        </Drawer>
      )}
    </>
  );
}
