export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export const addToCart = (game) => ({
  type: ADD_TO_CART,
  payload: game,
});

export const removeFromCart = (gameId) => ({
  type: REMOVE_FROM_CART,
  payload: gameId,
});
