// Library imports
import { Box, Modal } from "@mui/material";
import React from "react";
import { IoMdClose } from "react-icons/io";

export default function Shipping({ open, setOpen }) {
  const handleClose = () => setOpen(false);

  const costumeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "76%",
    bgcolor: "#EFEAE4",
    boxShadow: 24,
    overflow: "scroll",
    borderRadius: 2,
    "&:focus": {
      outline: "none",
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={costumeStyle}>
        <div className="bg-[#EFEAE4] fontStyle flex px-6 pt-6 pb-3 justify-between items-center sticky top-0">
          <p className="text-[24px]">Shipping policy</p>

          <IoMdClose
            cursor={"pointer"}
            size={24}
            color="#6D6B69"
            onClick={handleClose}
          />
        </div>

        <div className="fontStyle px-[20px]">
          <div className="text-[16px] p-[20px] lg:p-[20px] md:p-[18px] sm:p-[16px] phone:p-[14px] flex flex-col gap-3">
            <p className="font-bold text-[18px]">
              Thank you for choosing to shop with us!
            </p>
            <p className="font-semibold my-2">
              Below you will find our current shipping policy:
            </p>
            <p>
              At this time, we do not offer a cash-on-delivery (COD) option. All
              orders must be paid for in full before shipment. We accept various
              forms of payment, including credit cards, debit cards, and UPI.
            </p>

            <p>
              Once your order is placed, please allow 2-5 business days for
              processing. Orders are shipped out Monday through Friday,
              excluding holidays. Shipping times may vary based on your location
              and the shipping method selected at checkout.
            </p>

            <p>
              Please note that we are not responsible for any customs or duty
              fees that may be incurred during shipping. These fees are the
              responsibility of the customer and are not included in the
              shipping cost at checkout.
            </p>

            <p>
              If you have any questions about your order, please feel free to
              reach out to our customer service team at hello@opod.in. We strive
              to provide excellent customer service and will do our best to
              address any concerns you may have.
            </p>

            <p>
              Thank you for your understanding regarding the current lack of COD
              options. We hope to add this feature in the future to provide more
              flexibility to our customers.
            </p>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
