// Library imports
import { Box, Modal } from "@mui/material";
import React, { useContext, useState } from "react";
import { IoMdClose } from "react-icons/io";
import ButtonComponents from "../common/ButtonComponents";
import Carousel from "react-multi-carousel";
import { IoCheckmark } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import cart from "../../assets/pngs/cart_black.png";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/action/gamesCart";
import { FaMinus, FaPlay, FaPlus } from "react-icons/fa";
import { FaAngleLeft, FaAngleRight, FaQuoteLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { DropdownContext } from "../../context/dropdown";
import { decrement, increment } from "../../redux/action/gameCountAction";
import { removeFromCart } from "../../redux/action/gamesCart";
import { RiDeleteBin6Line } from "react-icons/ri";
import { OwlCarousel } from "../common/Carousel";
import { images } from "../../data/home";

export default function ProductPageModal({
  productPageModal,
  setProductPageModal,
}) {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isLaptop = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleClose = () =>
    setProductPageModal({ ...productPageModal, isOpen: false });
  const getGamesData = useSelector((state) => state.cart.items);
  const { setIsDropdownOpen } = useContext(DropdownContext);

  const costumeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    ...(isTablet && { height: "80%" }),
    maxHeight: "80%",
    bgcolor: "#EFEAE4",
    boxShadow: 24,
    overflow: "scroll",
    borderRadius: 2,
    "&:focus": {
      outline: "none",
    },
  };

  const handleAddToCart = (game) => {
    dispatch(addToCart(game));
  };

  const handlePreorder = (game) => {
    dispatch(addToCart(game));
    if (isMobile) {
      navigate("/checkout");
    } else {
      navigate("/preorder");
    }
  };

  const game = {
    gameId: productPageModal?.game?.gameId,
    image: productPageModal?.game?.image,
    title: productPageModal?.game?.title,
  };

  const gameData = getGamesData?.find((item) => item?.gameId === game.gameId);
  return (
    <Modal
      open={productPageModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={costumeStyle}>
        <div className="bg-[#EFEAE4] fontStyle flex p-5 justify-end items-center sticky top-0">
          <IoMdClose
            cursor={"pointer"}
            size={24}
            color="#6D6B69"
            onClick={handleClose}
          />
        </div>
        <div className=" flex max-md:flex-col md:h-[88%] px-7 pb-7 md:justify-between">
          <div className=" w-[100%] md:w-[63%] xl:w-[64%]">
            <OwlCarousel images={images} />
          </div>
          <div className=" w-[100%] md:w-[33%] xl:w-[34%] flex flex-col md:justify-between">
            <div className=" flex flex-col items-center">
              <h2 class="text-[28px] lg:text-[30px] font-bold text-[#161616]">
                {productPageModal?.game?.title}
              </h2>
              <p className="mediumStrategy text-[16px] text-[#6E6C6A] mb-3">
                {productPageModal?.game?.description1}
              </p>
              <p class="text-[14px] m-2 w-full text-[#161616] mediumStrategy text-left">
                {productPageModal?.game?.description2}
              </p>
              {/* <p class="text-[14px] m-2 w-full text-[#161616] mediumStrategy text-left">
                {productPageModal?.game?.description2}
              </p> */}
              <p class="text-[14px] m-2 w-full text-[#161616] mediumStrategy text-left">
                {productPageModal?.game?.productDetail}
              </p>
            </div>
            <div className="">
              {!!gameData && (
                <div className="flex justify-between">
                  <div className="flex">
                    <p className="crossed-text text-[20px] text-[#6D6B69] font-semibold font-family-Sans">
                      ₹699
                    </p>
                    <p className="text-[20px] text-[#161616] font-semibold font-family-Sans ml-[5px]">
                      Free
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <div
                      className="plusMinusStyle cursor-pointer"
                      onClick={() => dispatch(decrement(gameData.gameId))}
                    >
                      <FaMinus size={15} />
                    </div>
                    <div className="numberOfGamesStyle min-w-[30px] text-center text-[20px] border-x-[1px] border-[#7F7F7F]">
                      {gameData?.count}
                    </div>
                    <div
                      className="plusMinusStyle cursor-pointer"
                      onClick={() => dispatch(increment(gameData.gameId))}
                    >
                      <FaPlus size={15} />
                    </div>
                  </div>
                </div>
              )}
              <div
                className="mainButtonView my-4"
                style={{ ...(isLaptop && { gap: 4 }) }}
              >
                <div className="w-[62%] md:w-[56%]">
                  <ButtonComponents
                    style={{ height: isLaptop ? "40px" : "48px" }}
                  >
                    <p className=" md:max-lg:text-[14px] mx-1">HOW TO PLAY</p>
                    <div>
                      <FaPlay className=" md:max-lg:text-[12px]" />
                    </div>
                  </ButtonComponents>
                </div>
                <div className="w-[38%] md:w-[41%]">
                  <ButtonComponents
                    style={{ height: isLaptop ? "40px" : "48px" }}
                    onClick={() => handleAddToCart(game)}
                  >
                    {!!gameData ? (
                      <IoCheckmark size={isLaptop ? 15 : 18} color="black" />
                    ) : (
                      <GoPlus size={isLaptop ? 15 : 18} color="black" />
                    )}
                    <p className=" md:max-lg:text-[14px]">CART</p>
                    <div>
                      <img
                        src={cart}
                        className="w-auto md:max-lg:h-[15px] h-[20px]"
                      />
                    </div>
                  </ButtonComponents>
                </div>
              </div>
              <ButtonComponents
                className={"bg-[#FAA51A] my-5"}
                style={{ height: isLaptop ? "40px" : "48px" }}
                onClick={() => handlePreorder(game)}
              >
                PREORDER
              </ButtonComponents>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
