import React, { useState, useCallback } from 'react';
import './carousel.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

export const OwlCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleIndexChange = useCallback((newIndex) => {
    if (newIndex >= 0 && newIndex < images.length) {
      setCurrentIndex(newIndex);
    }
  }, [images.length]);

  const handleLeftIndex = useCallback(() => {
    handleIndexChange(currentIndex - 1);
  }, [currentIndex, handleIndexChange]);

  const handleRightIndex = useCallback(() => {
    handleIndexChange(currentIndex + 1);
  }, [currentIndex, handleIndexChange]);

  return (
    <div className="game-carousel-container select-none">
      <div className="game-carousel-top-image h-[70%] lg:h[65%]">
        <img src={images[currentIndex]} alt="" />
      </div>
      <div className="flex items-center justify-between h-[27%] lg:[32%] md:px-[4%] max-md:py-5">
        <FaAngleLeft
          className={`text-[16px] md:text-[22px] text-theme-black ${
            currentIndex === 0 ? 'cursor-no-drop' : 'cursor-pointer'
          }`}
          onClick={handleLeftIndex}
        />
        <div className="game-carousel-bottom-images">
          {images.map((image, index) => (
            <div
              key={index}
              className={`game-carousel-image-container ${
                currentIndex === index ? 'active' : ''
              }`}
              onClick={() => handleIndexChange(index)}
            >
              <img
                className="object-cover h-[105px] w-[305px] hover:opacity-60"
                src={image}
                alt=""
              />
            </div>
          ))}
        </div>
        <FaAngleRight
          className={`text-[16px] md:text-[22px] text-theme-black ${
            currentIndex === images.length - 1 ? 'cursor-no-drop' : 'cursor-pointer'
          }`}
          onClick={handleRightIndex}
        />
      </div>
    </div>
  );
};
